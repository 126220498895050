import React from 'react'
// import { Link } from '@reach/router'

import llama from '../../content/images/me-llama.png'
import me from '../../content/images/my-face.png'

export const AboutSidebar = () => {
  return (
    <aside className="post-sidebar-pages">
    <aside className="post-sidebar">
      <div className="post-sidebar-card">
        <h2>Me</h2>
        <img src={me} alt="Franco" />
      </div>
      <div className="post-sidebar-card">
        <h2>Me with Llama</h2>
        <img src={llama} alt="Llama" />
      </div>
    </aside>
    </aside>
  )
}
