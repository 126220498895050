import React from 'react'

import xkcdpgp from '../../content/images/pgp-xkcd.png'
import xkcdsecurityadvice from '../../content/images/xkcd-security-advice.png'

export const SecuritySidebar = () => {
  return (
    <aside className="post-sidebar-pages">
    <aside className="post-sidebar">
      <div className="post-sidebar-card">
        <h2>xkcd</h2>
        <img src={xkcdpgp} alt="xkcd joke" />
        </div>
      <div className="post-sidebar-card">
        <h2>Tips</h2>
        <img src={xkcdsecurityadvice} alt="xkcd joke advice" />
      </div>
    </aside>
    </aside>
  )
}
